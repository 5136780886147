
import { PropType } from "@vue/runtime-core";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import { Coordinator, Person, Student } from "@/interfaces/api";
import DetailsRow from "@/components/DetailsRow.vue";
import Select from "@/components/Form/Select.vue";
import DateHelper from "@/helpers/date-helper";
import LocalStorageService from "@/services/localstorage-service";
import CoordinatorService from "@/services/coordinator-service";
import DatePicker from "./Form/DatePicker.vue";
import StudentService from "@/services/student-service";
import store from "@/store";

@Options({
  components: {
    DetailsRow,
    Select,
    DatePicker,
  },
  props: {
    /**
     * Enables edit Coordinator dropdown
     */
    editCoordinator: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Show Coordinator dropdown
     */
    showCoordinator: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Required if showCoordinator
     * The functionId to fetch coordinators from
     */
    functionId: {
      type: Number as PropType<number>,
      required: false,
    },
    /**
     * Show date picker
     */
    showDatePicker: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * Show edit button
     */
    showEditButton: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * The Person object containing the data to be displayed
     */
    person: {
      type: Object as PropType<Person>,
      required: true,
    },
    /**
     * The Coordinator object containing the data to be displayed
     */
    coordinator: {
      type: Object as PropType<Coordinator>,
      required: false,
      default: {},
    },
    /**
     * If coordinator is required or not
     */
    requiredCoordinator: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    updateOnChange: {
      required: false,
      default: [],
    },
    /**
     * If the save button is disabled
     */
    saveButtonDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  emits: ["saveEdit", "editEnable", "cancelEdit", "newCoordinator"],
  data: () => ({
    /**
     * If edit-mode is enabled or not
     */
    editEnable: false,
    /**
     * A Person copy object as backup
     * to re-paste if you chose to cancel edit mode.
     */
    personCopy: {
      type: Object as PropType<Person>,
      default: {},
    },

    updateOnChangeCopy: {
      type: Array as PropType<unknown[]>,
      default: [],
    },
    /**
     * The coordinators working with the specified function (functionId)
     */
    coordinators: {
      type: Array as PropType<Array<Coordinator>>,
      required: false,
      default: [],
    },
    /**
     * Save call to service returns a boolean value idicating whether the save was successful or not
     * Used to display the result to the student
     */
    saveWasSuccessful: null,
    /**
     * The message to display when edit is saved
     */
    showSuccessMsg: null,
  }),
  methods: {
    /**
     * Saves the the changes made on the student profile
     */
    async save() {
      store.commit("showSpinner", true);
      const grantedSupportDate = DateHelper.formatDate(
        this.person.grantedSupportDate
      );

      StudentService.addOrUpdateStudent({
        ...this.person,
        grantedSupportDate: grantedSupportDate,
      })
        .then((response: Student) => {
          this.saveWasSuccessful = !!response;
          this.showSuccessMsg = true;
          this.editEnable = false;
          this.person.coordinator = this.coordinators.find(
            (coordinator: Coordinator) =>
              coordinator.id === this.person.coordinatorId
          );
          this.$emit("saveEdit");
        })
        .catch((e: Error) => {
          console.error(e);
        })
        .finally(() => {
          store.commit("showSpinner", false);
        });
    },

    /**
     * Activates edit-mode
     */
    edit() {
      this.editEnable = true;
      this.personCopy = { ...this.person };
      this.$emit("editEnable");

      this.updateOnChangeCopy = JSON.stringify(this.updateOnChange);
    },

    /**
     * Cancels edit mode and restores the person copy to null
     */
    cancel() {
      this.editEnable = false;
      Object.assign(this.person, this.personCopy);
      this.personCopy = null;
      this.showSuccessMsg = false;
      this.$emit("cancelEdit");
    },

    /**
     * Emit event to parent that the coordinator should be updated for the person
     */
    chooseCoordinator(): void {
      this.$emit("newCoordinator", this.person.coordinatorId);
    },

    /**
     * Gets the name for the coordinator with the specified Id
     */
    getCoordinatorName(coordinatorId: number): string {
      const coordinator = this.coordinators.find(
        (coordinator: Coordinator) => coordinator.id === coordinatorId
      );

      return coordinator ? coordinator.name : null;
    },
    /**
     * Fetch coordinators from the function with the specified Id
     */
    fetchCoordinators(functionId: number) {
      CoordinatorService.getCoordinatorsByFunctionId(functionId).then(
        (coordinators) => {
          if (coordinators) {
            this.coordinators = coordinators;
          }
        }
      );
    },
  },
  computed: {
    saveBtnDisabled() {
      return (
        this.saveButtonDisabled ||
        (JSON.stringify(this.personCopy) === JSON.stringify(this.person) &&
          this.updateOnChangeCopy === JSON.stringify(this.updateOnChange))
      );
    },
    /*
     * To format the displayed granted support date
     */
    formatedDate() {
      return dayjs(this.person.grantedSupportDate).format("YYYY-MM-DD");
    },
    /*
     * The Id of the current coordinator
     */
    coordinatorId() {
      return this.coordinator ? this.coordinator.id : null;
    },
    /*
     * A list of Ids for all the coordinators
     */
    coordinatorIds() {
      return this.coordinators.length
        ? this.coordinators.map((coordinator: Coordinator) => coordinator.id)
        : [];
    },
    showStudentDetails(): boolean {
      console.log("THIS PERSON: ", this.person);
      return this.person;
    },
  },
  mounted() {
    const localstorageLang = LocalStorageService.getItem<string>("language");
    this.$root.$i18n.locale = localstorageLang;
    this.saveWasSuccessful = null;
  },
  created() {
    if (this.showCoordinator) {
      this.fetchCoordinators(this.functionId);
    }
  },
})
export default class PersonDetails extends Vue {}
